@charset 'utf-8';

/* CSS Document */

//SCSS全局变量
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}
/* reset.css V1.6  Start*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font:normal 12px BlinkMacSystemFont, Helvetica Neue, PingFang SC, 'FZZhunYuan-M02S', '方正准圆简体', 'simsun',sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html {
    font-size: $baseFontSize;
    background-color: #fff;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device)*$baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}
.wp1345 {
    width: 1345px;
    margin: 0 auto;
    @media screen and (max-width: 1385px) {
        width: auto;
        margin: 0 px2rem(20px);
    }
}
.wp1258 {
    width: 100%;
    max-width: 1258px;
    margin: 0 auto;
}
.wp1200 {
    width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        width: auto;
        margin: 0 px2rem(20px);
    }
}
.wp960 {
    width: 960px;
    margin: 0 auto;
    @media screen and (max-width: 960px) {
        width: auto;
        margin: 0 px2rem(20px);
    }
}
.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */

// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式
.paged { padding-top: 15px; text-align: center; }
.paged a, .paged .p_info, .paged .current { display: inline-block; zoom: 1; *display: inline;
    margin: 0px 3px 5px; padding: 0 15px; font-size: 12px; line-height: 36px; text-align: center; background: #fff; color: #333; }
.paged .current, .paged a:hover { background: #79b0e9; color: #fff; }
.header {
    background-color: #fff;
    position: relative;
    z-index: 10;
    h1 {
        float: left;
        margin-left: 20px;
        a {
            display: block;
            height: px2rem(110px);
            img {
                display: block;
                width: auto;
                height: 100%;
            }
        }
    }
    &-search {
        float: right;
        position: relative;
        margin-left: 30px;
        z-index: 59;
        &:hover {
            .header-search-box {
                display: block;
            }
        }
        i {
            display: block;
            cursor: pointer;
            width: 30px;
            height: 110px;
            background: url(../images/icon_1.png) no-repeat center right;
        }
        &-box {
            display: none;
            position: absolute;
            top: 80px;
            right: 0;
            border: 1px solid #dedede;
            width: 200px;
            background-color: #fff;
            input {
                float: left;
                display: block;
                width: 150px;
                height: 36px;
                border: none;
                background: none;
                appearance: none;
                box-sizing: border-box;
                padding: 0 5px;
                font-size: 14px;
                line-height: 36px;
                color: #555;
            }
            button {
                display: block;
                float: left;
                width: 50px;
                height: 36px;
                background: url(../images/icon_1.png) no-repeat center center;
                background-size: auto 60%;
                cursor: pointer;
                border: none;
                appearance: none;
                font-size: 0;
            }
        }
    }
    @media screen and (max-width: 1150px) {
        &-search {
            display: flex;
            align-items: center;
            height: px2rem(110px);
            i {
                display: none;
            }
            &-box {
                display: block;
                position: static;
                border: 2px solid #26b3c8;
                width: px2rem(380px);
                input {
                    width: px2rem(280px);
                }
                button {
                    width: px2rem(100px);
                    background: #26b3c8;
                    font-size: 14px;
                    color: #fff;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-search {
            &-box {
                input {
                    height: 26px;
                    line-height: 26px;
                    font-size: 12px;
                }
                button {
                    font-size: 12px;
                    line-height: 26px;
                    height: 26px;
                }
            }
        }
    }
}
.nav {
    float: right;
    li {
        float: left;
        position: relative;
        > a {
            display: block;
            position: relative;
            font-size: 15px;
            color: #666;
            padding: 0 35px;
            line-height: 110px;
            z-index: 2;
            &:before {
                display: block;
                position: absolute;
                width: 1px;
                height: 8px;
                background-color: #b2b2b2;
                left: 0;
                top: 50%;
                margin-top: -4px;
                content: '';
            }
        }
        &:first-child {
            > a {
                &:before {
                    display: none;
                }
            }
        }
        .sub {
            display: none;
            position: absolute;
            width: 176px;
            background-color: #fff;
            left: 50%;
            transform: translateX(-50%);
            top: 100px;
            z-index: 5;
            &-item {
                border-top: 1px dashed #dedede;
                a {
                    display: block;
                    width: 100%;
                    text-align: center;
                    line-height: 46px;
                    height: 46px;
                    overflow: hidden;
                    font-size: 14px;
                    transition: background-color .2s ease;
                    &:hover {
                        background-color: #dae7f3;
                    }
                }
                &:first-child {
                    border-top: none;
                }
            }
        }
        &:hover,
        &.on {
            > a {
                color: #d00;
            }
        }
    }
    @media screen and (max-width: 1270px) {
        li {
            > a {
                padding: 0 25px;
            }
        }
    }
    @media screen and (max-width: 1150px) {
        display: none;
    }
}
.fast-con {
    padding: 55px 0 40px;
    background-color: #fff;
    .ewm {
        float: left;
        padding: 0 10px;
        .img {
            border: 1px solid #e0e0e0;
            padding: px2rem(8px);
            background-color: #fff;
            width: px2rem(166px);
            margin: 0 auto 10px;
            img {
                display: block;
                width: 100%;
            }
        }
        p {
            text-align: center;
            font-size: 12px;
            color: #777;
            width: 110px;
            margin: 0 auto;
            span {
                display: block;
            }
        }
    }
    .fast {
        float: right;
        width: 78%;
        display: flex;
        dl {
            flex: 1;
            box-sizing: border-box;
            border-left: 1px solid #e5e5e5;
            padding: 0 10px;
            &:first-child {
                border-left: none;
            }
            dt {
                margin-bottom: 20px;
                text-align: center;
                a {
                    font-size: 14px;
                    color: #555;
                    &:hover {
                        color: #d00;
                    }
                }
            }
            dd {
                margin-bottom: 10px;
                //padding-left: (50/150)*100%;
                text-align: center;
                a {
                    font-size: 12px;
                    color: #555;
                    &:hover {
                        color: #d00;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1150px) {
        > div {
            display: flex;
            align-items: center;
        }
        .fast {
            display: none;
        }
    }
    @media screen and (max-width: 750px) {
        padding: px2rem(40px) 0;
    }
}
.footer-mo-contact {
    display: none;
    .phone {
        font-size: px2rem(36px);
        color: #333;
        line-height: px2rem(40px);
        margin-bottom: 5px;
        &:before {
            display: inline-block;
            vertical-align: top;
            background: url(../images/icon_2.png) center no-repeat;
            height: px2rem(40px);
            width: px2rem(40px);
            margin-right: 5px;
            content: '';
            background-size: 100% 100%;
        }
    }
    .p {
        font-size: px2rem(24px);
        color: #555;
        line-height: 1.8em;
        a {
            color: #555;
            &:hover {
                color: #d00;
            }
        }
    }
    @media screen and (max-width: 1150px) {
        display: block;
    }
}
.mobile-menu {
    display: none;
    background-color: #f2f2f2;
    padding: px2rem(10px) 0 px2rem(10px) px2rem(54px);
    flex-wrap: wrap;
    .item {
        width: 33.33%;
        padding: px2rem(10px) px2rem(10px) px2rem(10px) 0;
        display: flex;
        align-items: center;
        font-size: 12px;
        box-sizing: border-box;
        color: #555;
        .icon {
            width: px2rem(62px);
            height: px2rem(62px);
            border-radius: 100%;
            margin-right: 10px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &:nth-child(1) {
            .icon {
                background-color: #f78bbc;
            }
        }
        &:nth-child(2) {
            .icon {
                background-color: #b5d272;
            }
        }
        &:nth-child(3) {
            .icon {
                background-color: #7bc8a6;
            }
        }
        &:nth-child(4) {
            .icon {
                background-color: #79b0e9;
            }
        }
        &:nth-child(5) {
            .icon {
                background-color: #5ac093;
            }
        }
        &:nth-child(6) {
            .icon {
                background-color: #e0d16f;
            }
        }
    }
    @media screen and (max-width: 1150px) {
        display: flex;
    }
}
.footer {
    padding: 40px 0;
    background-color: #121580;
    &-contact {
        float: left;
        .phone {
            font-size: 24px;
            color: #fff;
            line-height: 33px;
            margin-bottom: 5px;
            &:before {
                display: inline-block;
                vertical-align: top;
                background: url(../images/icon_2.png) center no-repeat;
                height: 33px;
                width: 33px;
                margin-right: 10px;
                content: '';
            }
        }
        .p {
            font-size: 14px;
            color: #fff;
            a {
                color: #fff;
                &:hover {
                    color: #d00;
                }
            }
        }
    }
    &-copyright {
        float: right;
        text-align: right;
        font-size: 13px;
        line-height: 30px;
        color: #fff;
        text-transform: uppercase;
        a {
            color: #fff;
            &:hover {
                color: #d00;
            }
        }
        p:first-child {
            border-bottom: 1px solid rgba(255, 255, 255, .1);
        }
    }
    @media screen {
        &-contact {
            display: none;
        }
        &-copyright {
            float: none;
            width: 100%;
            text-align: center;
        }
    }
}
.inside-banner {
    width: 100%;
    position: relative;
    z-index: 5;
    img {
        max-width: 100%;
        display: block;
    }
    &-mo {
        display: none;
        width: 100%;
        padding-bottom: (540/750)*100%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 750px) {
        display: none;
        &-mo {
            display: block;
        }
    }
}
.inside-swiper {
    height: 60px;
    background-color: #fff;
    box-shadow: 0 0 10px #d7d9de;
    overflow: hidden;
    &.no-init {
        .swiper-wrapper {
            text-align: center;
            width: 100%;
            display: block;
        }
    }
    .swiper-wrapper {
        font-size: 0;
    }
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        width: auto;
        padding: 15px 0;
        a {
            padding: 0 35px;
            position: relative;
            display: block;
            font-size: 14px;
            line-height: 30px;
            height: 30px;
            border-radius: 15px;
            color: #000;
            transition: all .2s ease;
            &:before {
                position: absolute;
                width: 1px;
                height: 8px;
                display: block;
                left: 0;
                top: 50%;
                margin-top: -4px;
                background: #ccc;
                content: '';
            }
        }
        &.on,
        &:hover {
            a {
                color: #fff;
                background-color: #79b0e9;
            }
            + .swiper-slide {
                a {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &:first-child,
        &.on {
            a {
                &:before {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        height: 50px;
        .swiper-slide {
            padding: 10px 0;
            a {
                font-size: 12px;
            }
        }
    }
}
.float-box {
    position: fixed;
    z-index: 999;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 1023px) {
        display: none;
    }
    li {
        border: 1px solid #cfcfcf;
        background-color: #fff;
        width: 76px;
        margin-bottom: 5px;
        img {
            width: 100%;
            display: block;
        }
        p {
            text-align: center;
            font-size: 12px;
            color: #666;
            line-height: 20px;
            height: 20px;
            overflow: hidden;
        }
    }
}