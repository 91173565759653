.index-banner {
    .swiper-slide {
        a {
            display: block;
        }
        span {
            display: block;
            width: 100%;
            padding-bottom: (788/1920)*100%;
            position: relative;
            img {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }
    .prev-btn {
        position: absolute;
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background-color: rgba(0, 0, 0, .5);
        top: 50%;
        margin-top: -18px;
        z-index: 19;
        cursor: pointer;
        border-radius: 100%;
        left: 100px;
        background-image: url(../images/arrow_6.png);
        background-position: center center;
        background-repeat: no-repeat;
        transform: rotate(180deg);
        &:hover {
            background-color: rgba(0, 0, 0, .8);
        }
    }
    .next-btn {
        position: absolute;
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background-color: rgba(0, 0, 0, .5);
        top: 50%;
        margin-top: -18px;
        z-index: 19;
        cursor: pointer;
        border-radius: 100%;
        right: 100px;
        background-image: url(../images/arrow_6.png);
        background-position: center center;
        background-repeat: no-repeat;
        &:hover {
            background-color: rgba(0, 0, 0, .8);
        }
    }
    .swiper-pagination {
        bottom: 20px;
        &-bullet {
            &-active {
                background-color: #d00;
            }
        }
    }
    &-mo {
        display: none;
        .swiper-slide {
            a {
                width: 100%;
                padding-bottom: (540/750)*100%;
                background-position: center top;
                background-repeat: no-repeat;
                background-size: cover;
                display: block;
            }
        }
        .swiper-pagination {
            &-bullet {
                &-active {
                    background-color: #d00;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        display: none;
        &-mo {
            display: block;
        }
    }
}
.index-box {
    padding: px2rem(70px) 0 px2rem(115px);
    background-color: #fff;
    background-image: url(../images/bg_4.jpg);
    background-position: center bottom;
    background-repeat: no-repeat;
}
.index-title {
    text-align: center;
    font-size: 35px;
    color: #333;
    @media screen and (max-width: 750px) {
        font-size: 18px;
    }
}
.index-slide {
    .hd {
        padding: 40px 0 20px;
        font-size: 0;
        text-align: center;
        li {
            display: inline-block;
            vertical-align: top;
            position: relative;
            padding: 0 px2rem(32px);
            cursor: pointer;
            img {
                display: block;
                width: px2rem(100px);
                max-width: 100%;
                margin: 0 auto;
            }
            p {
                text-align: center;
                line-height: 40px;
                height: 40px;
                overflow: hidden;
                font-size: 16px;
                color: #555;
                @media screen and (max-width: 750px) {
                    font-size: 12px;
                    height: 24px;
                    line-height: 24px;
                }
            }
            &.on {
                &:after {
                    position: absolute;
                    content: '';
                    width: 12px;
                    height: 8px;
                    background: url(../images/arrow_4.png) no-repeat center;
                    background-size: 100% 100%;
                    left: 50%;
                    margin-left: -6px;
                    bottom: -8px;
                }
            }
        }
    }
    .bd {
        .item {
            display: none;
            background-color: #fff;
            box-sizing: border-box;
            padding: px2rem(40px) px2rem(100px) px2rem(100px);
            &:first-child {
                display: block;
            }
            .img {
                display: block;
                float: left;
                width: 534px;
                max-width: 100%;
                img {
                    width: 100%;
                    display: block;
                }
                &:hover {
                    opacity: .9;
                }
            }
            .info {
                overflow: hidden;
                padding-left: 60px;
                padding-top: 30px;
                h4 {
                    font-size: 26px;
                    line-height: 32px;
                    height: 32px;
                    overflow: hidden;
                    margin-bottom: 25px;
                    a {
                        color: #000;
                    }
                }
            }
            .p {
                font-size: 14px;
                line-height: 32px;
                color: #888;
                height: 32px*6;
                overflow: hidden;
            }
            .more {
                display: block;
                width: 44px;
                height: 44px;
                border-radius: 100%;
                text-align: center;
                line-height: 44px;
                background-color: #f4f5f6;
                background-image: url(../images/arrow_5.png);
                background-position: center center;
                background-size: 100% 100%;
                transition: background-color .2s ease;
                &:hover {
                    background-color: darken(#f4f5f6, 5%);
                }
            }
            &:nth-child(1) {
                .info {
                    h4 {
                        a:hover {
                            color: #f78bbc;
                        }
                    }
                }
            }
            &:nth-child(2) {
                .info {
                    h4 {
                        a:hover {
                            color: #b5d272;
                        }
                    }
                }
            }
            &:nth-child(3) {
                .info {
                    h4 {
                        a:hover {
                            color: #7bc8a6;
                        }
                    }
                }
            }
            &:nth-child(4) {
                .info {
                    h4 {
                        a:hover {
                            color: #79b0e9;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .bd {
            .item {
                .img {
                    float: none;
                    margin: 0 auto;
                }
                .info {
                    padding-left: 0;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        .hd {
            padding-top: 20px;
            display: flex;
            li {
                flex: 1;
                padding: 0 5px;
            }
        }
        .bd {
            .item {
                padding: 0;
                .info {
                    padding: px2rem(20px) px2rem(40px) px2rem(50px);
                    h4 {
                        font-size: 14px;
                        color: #000;
                        margin-bottom: 0;
                    }
                }
                .p {
                    line-height: 26px;
                    height: auto;
                    margin-bottom: 10px;
                }
                .more {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
.index-pro {
    padding: px2rem(70px) 0;
    background-color: #fff;
}
.index-pro-box {
    .hd {
        padding: 40px 0 45px;
        font-size: 0;
        text-align: center;
        @media screen and (max-width: 750px) {
            display: flex;
            padding: 20px 0;
        }
        li {
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            line-height: 68px;
            cursor: pointer;
            position: relative;
            @media screen and (max-width: 750px) {
                flex: 1;
                padding: 0;
            }
            img {
                display: inline-block;
                vertical-align: top;
                width: 68px;
                height: 68px;
                margin-right: 10px;
                @media screen and (max-width: 750px) {
                    width: 50px;
                    height: 50px;
                    margin-right: 0;
                }
            }
            p {
                display: inline-block;
                vertical-align: top;
                font-size: 16px;
                color: #555;
                @media screen and (max-width: 750px) {
                    font-size: 12px;
                    line-height: 24px;
                }
            }
            &.on {
                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 12px;
                    height: 8px;
                    background: url(../images/arrow_4.png) no-repeat center;
                    background-size: 100% 100%;
                    bottom: -15px;
                    left: 48px;
                }
            }
            @media screen and (max-width: 1000px) {
                line-height: 24px;
                img {
                    display: block;
                    max-width: 100%;
                    margin: 0 auto 10px;
                }
                &.on {
                    &:after {
                        left: 50%;
                        margin-left: -6px;
                        bottom: -10px;
                    }
                }
            }
        }
    }
    .bd {
        .item {
            display: none;
            &:first-child {
                display: block;
            }
            .box {
                position: relative;
                margin-bottom: 30px;
            }
            .recommend-box {
                float: right;
                width: 50%;
                span {
                    display: block;
                    width: 100%;
                    padding-bottom: (460/660)*100%;
                    position: relative;
                    img {
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
                &:hover {
                    opacity: .8;
                }
            }
            .index-pro-list {
                padding-bottom: (468/1345)*100%;
                li {
                    position: absolute;
                    width: 25%;
                    height: 50%;
                    box-sizing: border-box;
                    padding-right: 14px;
                    &:nth-child(1),
                    &:nth-child(2) {
                        padding-bottom: 7px;
                    }
                    &:nth-child(3),
                    &:nth-child(4) {
                        padding-top: 7px;
                        top: 50%;
                    }
                    &:nth-child(2n+2) {
                        left: 25%;
                    }
                    a {
                        display: block;
                        background-color: #f6f6f6;
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        overflow: hidden;
                    }
                    .img {
                        position: absolute;
                        right: 14px;
                        top: 40px;
                        bottom: 35px;
                        overflow: hidden;
                        text-align: right;
                        width: 120px;
                        img {
                            display: block;
                            position: absolute;
                            max-width: 100%;
                            max-height: 100%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .info {
                        position: absolute;
                        top: 50px;
                        left: 35px;
                        //width: 130px;
                        right: 137px;
                        h4 {
                            font-size: 14px;
                            color: #444;
                            line-height: 22px;
                            height: 44px;
                            overflow: hidden;
                            margin-bottom: 30px;
                            transition: color .2s ease;
                        }
                    }
                    .arrow {
                        display: block;
                        border-radius: 100%;
                        border: 1px solid #cecece;
                        width: 28px;
                        height: 28px;
                        background: #fff url(../images/arrow_5.png) no-repeat center;
                        background-size: 100% 100%;
                    }
                }
            }
            &:nth-child(1) {
                .index-pro-list {
                    li:hover {
                        .info {
                            h4 {
                                color: #f78bbc;
                            }
                        }
                    }
                }
            }
            &:nth-child(2) {
                .index-pro-list {
                    li:hover {
                        .info {
                            h4 {
                                color: #b5d272;
                            }
                        }
                    }
                }
            }
            &:nth-child(3) {
                .index-pro-list {
                    li:hover {
                        .info {
                            h4 {
                                color: #7bc8a6;
                            }
                        }
                    }
                }
            }
            &:nth-child(4) {
                .index-pro-list {
                    li:hover {
                        .info {
                            h4 {
                                color: #79b0e9;
                            }
                        }
                    }
                }
            }
            .more {
                display: block;
                width: 220px;
                height: 40px;
                text-align: center;
                border-radius: 22px;
                border: 1px solid #e6e6e6;
                background-color: #fbfbfb;
                margin: 0 auto;
                font-size: 14px;
                color: #777;
                line-height: 40px;
                transition: background-color .2s ease;
                &:hover {
                    background-color: darken(#fbfbfb, 5%);
                }
            }
            @media screen and (max-width: 1000px) {
                .recommend-box {
                    float: none;
                    width: 100%;
                }
                .index-pro-list {
                    width: 100%;
                    font-size: 0;
                    padding-bottom: 0;
                    li {
                        position: relative;
                        left: auto !important;
                        top: auto !important;
                        width: 50%;
                        height: auto;
                        display: inline-block;
                        vertical-align: top;
                        padding-top: px2rem(20px) !important;
                        padding-bottom: 0 !important;
                        .img {
                            position: relative;
                            text-align: center;
                            width: 100%;
                            margin: 0 auto px2rem(20px);
                            height: px2rem(160px);
                            left: auto;
                            right: auto;
                            bottom: auto;
                            top: auto;
                            img {
                                //display: inline-block;
                                //vertical-align: top;
                                //position: static;
                                //transform: translate(0, 0);
                            }
                        }
                        .info {
                            position: static;
                            width: 100%;
                            h4 {
                                margin-bottom: 5px;
                                text-align: center;
                                font-size: 12px;
                                line-height: 16px;
                                height: 32px;
                            }
                        }
                        a {
                            padding: px2rem(30px);
                        }
                        .arrow {
                            margin: 0 auto;
                            width: 16px;
                            height: 16px;
                        }
                        &:nth-child(2n+1) {
                            padding-left: 0 !important;
                            padding-right: px2rem(10px) !important;
                        }
                        &:nth-child(2n+2) {
                            padding-left: px2rem(10px) !important;
                            padding-right: 0 !important;
                        }
                    }
                }
            }
        }
    }
}
.index-about {
    position: relative;
    @media screen and (max-width: 750px) {
        background-image: url(../images/bg_7.jpg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        background-color: #f7f7f7;
    }
    .bg {
        width: 100%;
        height: 614px;
        //padding-bottom: (614/1920)*100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 1280px) {
            height: 550px;
        }
        @media screen and (max-width: 750px) {
            display: none;
        }
    }
    > img {
        display: block;
        width: 100%;
    }
    img {
        max-width: 100%;
    }
    .bgf {
        background-color: #fff;
    }
    .word-box {
        position: absolute;
        width: 1345px;
        max-width: 100%;
        top: 110px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        @media screen and (max-width: 750px) {
            display: none;
        }
        &:after {
            width: 90%;
            height: 159px;
            position: absolute;
            left: 50%;
            bottom: -100px;
            content: '';
            display: block;
            background: url(../images/shadow_2.png) center bottom no-repeat;
            background-size: 100% auto;
            z-index: 2;
            transform: translateX(-50%);
        }
        .flex-box {
            position: relative;
            z-index: 10;
        }
        p {
            //padding: px2rem(30px) 0;
            //line-height: 32px;
            color: #888;
            font-size: 13px;
        }
        .tip {
            color: #cacaca;
            margin-bottom: 15px;
        }
        h3 {
            font-size: 30px;
            color: #333;
        }
        .info {
            font-size: 13px;
            line-height: 28px;
            color: #888;
        }
    }
    .flex-box {
        display: flex;
        align-items: center;
    }
    .flex-1 {
        flex: 1;
        box-sizing: border-box;
        padding: px2rem(30px) px2rem(60px);
    }
    .img-2 {
        position: relative;
        width: (685/1345)*100%;
        padding-bottom: (588/1345)*100%;
        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .readmore {
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            width: px2rem(68px);
            height: px2rem(68px);
            background-color: #f98383;
            background-image: url(../images/arrow.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 30% auto;
            margin-top: px2rem(-34px);
            z-index: 5;
            transition: background-color .2s ease;
            &:hover {
                background-color: lighten(#f98383, 5%);
            }
        }
    }
    .about-map {
        width: 100%;
        background-color: #fefefe;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        padding: px2rem(135px) 0 px2rem(80px);
        h5 {
            text-align: center;
            font-size: 30px;
            color: #555;
            margin-bottom: 15px;
        }
        p {
            width: 800px;
            max-width: 100%;
            margin: 0 auto 15px;
            text-align: center;
            color: #888;
            line-height: 2em;
        }
        .img {
            text-align: center;
        }
        img {
            max-width: 100%;
        }
        @media screen and (max-width: 750px) {
            background: none !important;
            padding-top: 0;
            h5 {
                font-size: 18px;
            }
            p {
                font-size: 12px;
                padding: 0 px2rem(40px);
                box-sizing: border-box;
            }
        }
    }
}
.word-box-mobile {
    display: none;
    padding: px2rem(50px) px2rem(20px);
    background-image: url(../images/bg_6.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    @media screen and (max-width: 750px) {
        display: block;
    }
    .info {
        background-color: #fff;
        position: relative;
        &:after {
            width: 90%;
            height: px2rem(100px);
            position: absolute;
            left: 50%;
            bottom: px2rem(-50px);
            content: '';
            display: block;
            background: url(../images/shadow_2.png) center bottom no-repeat;
            background-size: 100% auto;
            z-index: 2;
            transform: translateX(-50%);
        }
    }
    .box {
        background-color: #fff;
        position: relative;
        z-index: 5;
        padding-top: px2rem(40px);
        h3 {
            text-align: center;
            font-size: 18px;
            color: #555;
        }
        .tip {
            font-size: 12px;
            color: #cacaca;
            text-align: center;
            margin-bottom: 10px;
        }
        .img {
            display: block;
        }
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
    .p {
        padding: px2rem(30px) px2rem(50px) px2rem(60px);
        font-size: 12px;
        line-height: 22px;
        color: #888;
    }
}