.about-con {
    .con-1 {
        background: #eef1f6;
        padding: px2rem(50px) 0;
    }
    .box {
        padding: px2rem(30px) 0;
        line-height: 32px;
        color: #666;
        font-size: 13px;
        img {
            max-width: 100%;
            height: auto !important;
        }
        h3 {
            font-size: 30px;
            color: #333;
            margin-bottom: 15px;
        }
    }
    .img-1 {
        float: left;
        margin-right: px2rem(70px);
        margin-bottom: px2rem(30px);
    }
    .flex-box {
        display: flex;
        align-items: center;
    }
    .flex-1 {
        flex: 1;
        box-sizing: border-box;
        padding: px2rem(30px) px2rem(60px);
        &.pl {
            padding-left: px2rem(124px);
        }
        &.pd {
            padding: px2rem(30px) px2rem(100px);
        }
    }
    .bgf {
        background-color: #fff;
    }
    .con-2 {
        position: relative;
        .bg {
            width: 100%;
            height: 520px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        > img {
            display: block;
            width: 100%;
        }
        img {
            max-width: 100%;
        }
        .word-box {
            position: absolute;
            width: 1345px;
            max-width: 100%;
            top: 110px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
            &:after {
                width: 90%;
                height: 159px;
                position: absolute;
                left: 50%;
                bottom: -100px;
                content: '';
                display: block;
                background: url(../images/shadow_2.png) center bottom no-repeat;
                background-size: 100% auto;
                z-index: 2;
                transform: translateX(-50%);
            }
            .flex-box {
                position: relative;
                z-index: 10;
            }
            p {
                //padding: px2rem(30px) 0;
                line-height: 32px;
                color: #888;
                font-size: 13px;
            }
            h3 {
                font-size: 30px;
                color: #333;
                margin-bottom: 15px;
            }
        }
    }
    .about-map {
        width: 100%;
        background-color: #fefefe;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        padding: px2rem(135px) 0 px2rem(80px);
    }
    @media screen and (max-width: 1258px) {
        .con-1 {
            padding-right: px2rem(20px);
            padding-left: px2rem(20px);
        }
    }
    @media screen and (max-width: 1200px) {
        .con-1 {
            .flex-box {
                display: block;
            }
            .img-2 {
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 1100px) {
        .con-2 {
            .bg {
                display: none;
            }
            .word-box {
                position: relative;
                top: 0;
                &:after {
                    bottom: px2rem(-60px);
                }
                .flex-box {
                    display: block;
                }
                .img-2 {
                    text-align: center;
                }
            }
        }
        .flex-1 {
            &.pl {
                padding-left: px2rem(60px);
            }
        }
    }
    @media screen and (max-width: 750px) {
        .box {
            h3 {
                font-size: 22px;
            }
        }
        .con-2 {
            .word-box {
                h3 {
                    font-size: 22px;
                }
            }
        }
    }
}
.contact-con {
    padding: px2rem(90px) 0 px2rem(70px);
    background-color: #eef1f6;
}
.contact-box {
    width: 1250px;
    margin: 0 auto;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: px2rem(50px);
    background-color: #fff;
    align-items: center;
    .word {
        padding: 0 px2rem(50px);
        font-size: 13px;
        line-height: 28px;
        color: #777;
        flex: 1;
        box-sizing: border-box;
        h5 {
            font-size: 22px;
            color: #444;
            margin-bottom: 15px;
        }
        .ewm {
            display: block;
            border: 1px solid #e0e0e0;
            padding: 5px;
            background-color: #fff;
            box-sizing: border-box;
            max-width: 182px;
            margin: 10px 0;
            img {
                width: 100%;
                display: block;
            }
        }
    }
    .map-box {
        width: 100%;
        max-width: 608px;
        img {
            display: block;
            width: 100%;
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        .word {
            width: 100%;
            margin-bottom: px2rem(40px);
            padding: 0;
        }
    }
}
$mainBlue: #79b0e9;
.news-info {
    background-color: #eef1f6;
    padding: 60px 0 80px;
    &.bg {
        background-image: url(../images/bg_8.jpg);
    }
    @media screen and (max-width: 640px) {
        padding: px2rem(30px) 0 px2rem(40px);
    }
}
.news-title {
    //max-width: 1005px;
    margin: 0 auto;
    border-bottom: 1px dashed #cdcdcd;
    text-align: center;
    padding-bottom: 25px;
    h2 {
        font-size: 30px;
        color: #333;
        margin-bottom: 10px;
    }
    .tips {
        font-size: 14px;
        color: #333;
    }
    @media screen and (max-width: 640px) {
        box-sizing: border-box;
        padding-left: px2rem(20px);
        padding-right: px2rem(20px);
        h2 {
            font-size: 20px;
        }
    }
}
.news-article {
    padding-top: 30px;
    font-size: 13px;
    line-height: 30px;
    color: #666;
    //max-width: 1005px;
    margin: 0 auto;
    @include articleReset(13px, 30px);
    @media screen and (max-width: 640px) {
        box-sizing: border-box;
        padding-left: px2rem(20px);
        padding-right: px2rem(20px);
    }
}
.news-paged {
    background-color: #eef1f6;
    li {
        float: left;
        border: 1px solid #e5e5e5;
        width: 33.333%;
        box-sizing: border-box;
    }
    a {
        display: block;
        text-align: center;
        height: 56px;
        line-height: 56px;
        overflow: hidden;
        font-size: 13px;
        color: #000;
        &:hover {
            color: $mainBlue;
        }
    }
    &-prev {
        a:before {
            display: inline-block;
            vertical-align: top;
            height: 56px;
            width: 10px;
            margin-right: 30px;
            background: url(../images/arrow_2.png) left center no-repeat;
            content: '';
        }
    }
    &-list {
        a:before {
            display: inline-block;
            vertical-align: top;
            height: 56px;
            width: 20px;
            margin-right: 20px;
            background: url(../images/icon_14.png) left center no-repeat;
            content: '';
        }
    }
    &-next {
        a:after {
            display: inline-block;
            vertical-align: top;
            height: 56px;
            width: 10px;
            margin-left: 30px;
            background: url(../images/arrow_3.png) right center no-repeat;
            content: '';
        }
    }
    @media screen and (max-width: 640px) {
        &-prev, &-list {
            a:before {
                margin-right: px2rem(20px);
            }
        }
        &-next {
            a:after {
                margin-left: px2rem(20px);
            }
        }
    }
}
.product-detail {
    background-color: #e3e7ec;
    background-image: url(../images/bg_3.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    padding: px2rem(50px) 0;
}
.product-back-btn {
    text-align: right;
    margin-bottom: 9px;
    a {
        display: inline-block;
        vertical-align: top;
        width: 120px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        font-size: 14px;
        color: #333;
        background-color: #fff;
        border-radius: 3px;
        transition: all .2s ease;
        &:hover {
            background-color: $mainBlue;
            color: #fff;
        }
    }
}
.product-detail-top {
    position: relative;
    margin-bottom: 40px;
    &:after {
        position: absolute;
        display: block;
        width: 100%;
        background: url(../images/shadow_3.png) no-repeat center bottom;
        background-size: 100% auto;
        left: 0;
        bottom: px2rem(-30px);
        content: '';
        height: px2rem(68px);
        opacity: .3;
    }
    .box {
        position: relative;
        box-sizing: border-box;
        z-index: 5;
        background-color: #fff;
        padding: 50px 120px 80px;
        display: flex;
        .detail {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 90px;
            padding-right: 30px;
            h2 {
                font-size: 30px;
                line-height: 36px;
                padding-bottom: 20px;
                border-bottom: 1px dashed #a5a5a5;
                margin-bottom: 20px;
                color: #333;
            }
            .p {
                font-size: 16px;
                color: #555;
                line-height: 36px;
                margin-bottom: 40px;
            }
            .buy-btn {
                display: block;
                width: 200px;
                height: 50px;
                cursor: pointer;
                text-align: center;
                line-height: 50px;
                color: #fff;
                font-weight: bold;
                font-size: 18px;
                background-color: #66b592;
                transition: background-color .2s ease;
                i {
                    display: inline-block;
                    vertical-align: top;
                    width: 26px;
                    height: 50px;
                    background: url(../images/icon_15.png) no-repeat center;
                    background-size: 100% auto;
                    margin-right: 12px;
                }
                &:hover {
                    background-color: lighten(#66b592, 5%);
                }
            }
        }
    }
    .box-img {
        width: 400px;
    }
    .big-swiper {
        margin-bottom: 20px;
        .swiper-slide {
            span {
                position: relative;
                display: block;
                width: 100%;
                padding-bottom: 100%;
                img {
                    position: absolute;
                    max-width: 100%;
                    max-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    .small-swiper {
        margin: 0 auto;
        width: 250px;
        .swiper-slide {
            cursor: pointer;
            span {
                display: block;
                border: 1px solid #efefef;
                width: 58px;
                height: 58px;
                position: relative;
                img {
                    position: absolute;
                    max-width: 100%;
                    max-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &.on {
                span {
                    border-color: #bcbec3;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .box {
            padding-right: 50px;
            padding-left: 50px;
        }
    }
    @media screen and (max-width: 900px) {
        .box {
            padding: px2rem(50px);
            display: block;
            .detail {
                display: block;
                padding: 0;
                h2 {
                    font-size: 20px;
                }
                .p {
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
        .box-img {
            margin: 0 auto px2rem(40px);
            max-width: 100%;
        }
    }
}
.product-detail-bottom {
    background-color: #fff;
    padding: px2rem(70px) px2rem(20px);
    .box {
        width: 1076px;
        max-width: 100%;
        margin: 0 auto;
    }
}
.product-recommend {
    float: left;
    width: 253px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    .title {
        height: 57px;
        line-height: 57px;
        box-sizing: border-box;
        padding: 0 30px;
        background-color: #fafafa;
        font-size: 13px;
        color: #333;
        border-bottom: 1px solid #d9d9d9;
        &:before {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            width: 29px;
            height: 57px;
            background: url(../images/icon_16.png) no-repeat center;
            background-size: 100% auto;
            content: '';
        }
    }
    ul {
        padding: 0 20px;
        li {
            border-bottom: 1px solid #e3e3e3;
            a {
                display: block;
                padding-bottom: 30px;
                padding-top: 30px;
                &:hover {
                    p {
                        color: $mainBlue;
                    }
                }
            }
            img {
                display: block;
                width: 200px;
                max-width: 100%;
                margin: 0 auto 15px;
            }
            p {
                text-align: center;
                font-size: 13px;
                line-height: 30px;
                height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #555;
                transition: color .2s ease;
            }
        }
    }
    .more {
        display: block;
        text-align: center;
        height: 60px;
        line-height: 60px;
        font-size: 13px;
        color: #666;
        &:hover {
            color: $mainBlue;
        }
    }
    @media screen and (max-width: 800px) {
        display: none;
    }
}
.product-article {
    overflow: hidden;
    padding-left: 30px;
    color: #333;
    line-height: 30px;
    @include articleReset(13px, 30px);
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
}