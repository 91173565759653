.news-con {
    background-color: #eef1f6;
    padding: 50px 0 40px;
    position: relative;
    z-index: 3;
}
.product-con {
    background-color: #eef1f6;
    position: relative;
    padding-bottom: 36px;
}
.news-list {
    width: (376px+34px)*3;
    margin: 0 auto;
    li {
        float: left;
        width: (100% /3);
        margin-bottom: 30px;
        position: relative;
        box-sizing: border-box;
        padding: 0 px2rem(17px);
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            background: url(../images/shadow_1.png) center bottom no-repeat;
            background-size: 100% auto;
            bottom: -50px;
            height: 100px;
            display: block;
            content: '';
        }
        &:nth-child(3n+1) {
            .arrow {
                background-color: #f78bbc;
            }
        }
        &:nth-child(3n+2) {
            .arrow {
                background-color: #b5d272;
            }
        }
        &:nth-child(3n+3) {
            .arrow {
                background-color: #7bc8a6;
            }
        }
    }
    .box {
        transition: all .5s;
        display: block;
        position: relative;
        z-index: 5;
        &:hover {
            transform: translate(0, -10px);
            .box-img img {
                opacity: .8;
                transform: scale(1.05);
            }
        }
    }
    .box-img {
        overflow: hidden;
        position: relative;
        padding-top: (250/376)*100%;
        > img {
            transition: all .5s;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .info {
        background-color: #fff;
        padding: 20px 35px;
        overflow: hidden;
    }
    h4 {
        transition: all .5s;
        height: 60px;
        line-height: 30px;
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
        overflow: hidden;
    }
    .arrow {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        display: inline-block;
        vertical-align: top;
        background-image: url(../images/arrow.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        margin-bottom: 10px;
    }
}
@media(max-width: 1230px) {
    .news-list {
        width: 100%;
    }
}
@media(max-width: 1000px) {
    .news-list {
        li {
            float: left;
            width: 50%;
        }
    }
}
@media(max-width: 640px) {
    .news-list {
        li {
            &:after {
                bottom: -20px;
            }
        }
        h4 {
            font-size: 14px;
            line-height: 24px;
            height: 48px;
        }
        .info {
            padding: px2rem(20px) px2rem(35px);
        }
    }
}
.nutrition-list {
    li {
        float: left;
        width: 50%;
        box-sizing: border-box;
        margin-bottom: 20px;
        &:nth-child(2n+1) {
            padding-right: 17px;
            .info {
                .arrow {
                    background-color: #f78bbc;
                }
            }
            &:hover {
                .info {
                    h5 {
                        color: #f78bbc;
                    }
                }
            }
        }
        &:nth-child(2n+2) {
            padding-left: 17px;
            .info {
                .arrow {
                    background-color: #b5d272;
                }
            }
            &:hover {
                .info {
                    h5 {
                        color: #b5d272;
                    }
                }
            }
        }
        &:hover {
            .img {
                span {
                    img {
                        transform: scale(1.04, 1.04);
                    }
                }
            }
        }
        a {
            display: flex;
            background-color: #fff;
            overflow: hidden;
        }
        .img {
            width: 330px;
            height: 230px;
            overflow: hidden;
            span {
                display: block;
                position: relative;
                height: 100%;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: all .4s ease;
                }
            }
        }
        .info {
            flex: 1;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            padding: 30px 40px;
            //height: 230px;
            h5 {
                font-size: 18px;
                margin-bottom: 10px;
                line-height: 24px;
                max-height: 48px;
                overflow: hidden;
                color: #333;
                transition: all .4s ease;
            }
            .p {
                font-size: 14px;
                line-height: 24px;
                max-height: 48px;
                overflow: hidden;
                color: #777;
            }
            .arrow {
                position: absolute;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 50%;
                background-image: url(../images/arrow.svg);
                left: 40px;
                bottom: 30px;
                display: block;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            .info {
                padding: px2rem(20px);
                .arrow {
                    left: px2rem(20px);
                    bottom: px2rem(20px);
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            width: 100%;
            &:nth-child(2n+1) {
                padding-right: 0;
            }
            &:nth-child(2n+2) {
                padding-left: 0;
            }
        }
    }
    @media screen and (max-width: 640px) {
        li {
            a {
                display: block;
            }
            .img {
                width: 100%;
                height: auto;
                span {
                    width: 100%;
                    height: 0;
                    padding-bottom: (230/330)*100%;
                }
            }
            .info {
                padding: px2rem(40px);
                h5 {
                    font-size: 16px;
                }
                .p {
                    margin-bottom: 10px;
                }
                .arrow {
                    position: static;
                }
            }
        }
    }
}
.product-menu {
    margin-top: -50px;
    position: relative;
    z-index: 15;
    //overflow: hidden;
    background-color: #fff;
    margin-bottom: 45px + 72px;
    .top {
        //padding: 20px 0;
        font-size: 0;
        text-align: center;
        position: relative;
        .item {
            display: inline-block;
            vertical-align: top;
            .wp {
                padding: 20px 50px;
                line-height: 46px;
                font-size: 14px;
                color: #000;
                box-sizing: border-box;
                position: relative;
                display: block;
                z-index: 5;
                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    content: '';
                    width: 1px;
                    height: 10px;
                    margin-top: -5px;
                    background-color: #ccc;
                }
            }
            img {
                display: inline-block;
                vertical-align: top;
                width: 46px;
                height: 46px;
                margin-right: 15px;
            }
            span {
                display: inline-block;
                vertical-align: top;
            }
            &:hover,
            &.on {
                .wp {
                    color: #4aab80;
                }
            }
            &:first-child {
                .wp {
                    &:before {
                        display: none;
                    }
                }
            }
            &.on {
                .wp {
                    span {
                        font-weight: bold;
                    }
                }
                .sub-item-con {
                    display: block;
                    + .wp {
                        &:after {
                            position: absolute;
                            display: block;
                            content: '';
                            width: 16px;
                            height: 9px;
                            background: url(../images/icon_13.jpg) center no-repeat;
                            background-size: 100% 100%;
                            left: 50%;
                            margin-left: -8px;
                            bottom: -9px;
                        }
                    }
                }
            }
            &:hover {
                .sub-item-con {
                    z-index: 6;
                    + .wp {
                        z-index: 7;
                        &:after {
                            position: absolute;
                            display: block;
                            content: '';
                            width: 16px;
                            height: 9px;
                            background: url(../images/icon_13.jpg) center no-repeat;
                            background-size: 100% 100%;
                            left: 50%;
                            margin-left: -8px;
                            bottom: -9px;
                        }
                    }
                }
            }
        }
    }
    .sub-item-con {
        border-top: 1px solid #e0e0e0;
        width: 100%;
        overflow: hidden;
        display: none;
        position: absolute;
        left: 0;
        top: 86px;
        background-color: #fff;
    }
    .sub-swiper {
        width: 100%;
        overflow: hidden;
        height: 72px;
        .swiper-wp {
            justify-content: center;
            display: flex;
            position: relative;
            left: 0;
            top: 0;
        }
        &.tl {
            .swiper-wp {
                justify-content: left;
            }
        }
        .swiper-slide {
            width: auto;
            a {
                display: block;
                padding: 0 50px;
                font-size: 14px;
                color: #444;
                line-height: 72px;
                &:hover {
                    color: #4aab80;
                }
            }
            &.on {
                a {
                    color: #4aab80;
                    font-weight: bold;
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .top {
            display: flex;
            .item {
                flex: 1;
                img {
                    margin-right: 0;
                }
                .wp {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 5px;
                    font-size: 12px;
                }
            }
        }
        .sub-item-con {
            top: 112px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 70px;
        .top {
            padding: 0;
        }
        .sub-item-con {
            top: 112px;
        }
        .sub-swiper {
            height: 50px;
            .swiper-slide {
                a {
                    padding: 0 px2rem(50px);
                    line-height: 50px;
                    font-size: 12px;
                }
            }
        }
    }
}
.product-list {
    width: (272px*5);
    margin: 0 auto;
    li {
        float: left;
        width: 20%;
        box-sizing: border-box;
        padding: 0 10px;
        margin-bottom: 20px;
        a {
            display: block;
            background-color: #fff;
            padding: 30px 10px 40px;
            transition: all .5s ease;
            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 5px 10px rgba(99, 99, 99, .1);
                .p {
                    color: #4aab80;
                }
            }
        }
        .img {
            width: 100%;
            padding-bottom: 100%;
            position: relative;
            margin-bottom: 15px;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        .p {
            text-align: center;
            font-size: 13px;
            line-height: 30px;
            height: 30px;
            overflow: hidden;
            color: #555;
            margin-bottom: 15px;
        }
        .arrow {
            border: 1px solid #d6d6d6;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            background-color: #f1f1f1;
            line-height: 22px;
            text-align: center;
            color: #888;
            font-size: 14px;
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 1360px) {
        width: auto;
        margin-right: 10px;
        margin-left: 10px;
    }
    @media screen and (max-width: 1200px) {
        li {
            width: 25%;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            width: 50%;
            a {
                padding-top: px2rem(30px);
                padding-bottom: px2rem(40px);
            }
            &:nth-child(2n+1) {
                padding-left: 0;
                padding-right: 5px;
            }
            &:nth-child(2n+2) {
                padding-left: 5px;
                padding-right: 0;
            }
        }
    }
}
.talent-recruitment-title {
    background: #6da5df;
    height: 53px;
    line-height: 53px;
    font-size: 0;
    margin-bottom: 18px;
    li {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        font-size: 16px;
        color: #fff;
        width: 155px;
    }
    .name {
        width: 286px;
    }
    @media screen and (max-width: 1150px) {
        .dn1150 {
            display: none;
        }
        li, .name {
            width: 23%;
        }
    }
    @media screen and (max-width: 800px) {
        padding-right: px2rem(60px);
        box-sizing: border-box;
        li, .name {
            width: 25%;
            font-size: px2rem(24px);
        }
    }
}
.talent-recruitment-list {
    box-sizing: border-box;
    .active {
        .talent-title {
            .icon-rotate {
                span {
                    transform: rotate(180deg);
                }
            }
        }
    }
    > li:first-child {
        margin-top: 0;
        .nr {
            display: block;
        }
    }
    > li {
        //border: 1px solid #d7d7d7;
        background: #fff;
        margin-top: 20px;
        .talent-title {
            position: relative;
            width: 100%;
            height: 53px;
            line-height: 16px;
            font-size: 0;
            cursor: pointer;
            overflow: hidden;
            li {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
                display: inline-block;
                vertical-align: top;
                text-align: center;
                font-size: 14px;
                height: 16px;
                color: #333;
                overflow: hidden;
                width: 155px;
                padding: 0 6px;
                box-sizing: border-box;
            }
            .name {
                width: 286px;
            }
            @media screen and (max-width: 1150px) {
                .dn1150 {
                    display: none;
                }
                li, .name {
                    width: 23%;
                }
            }
            .icon-rotate {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 72px;
                transform: translate(0, 0%);
                line-height: 53px;
                span {
                    transition: all .4s ease;
                    display: inline-block;
                    vertical-align: middle;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    transform: rotate(0);
                    background: url(../images/icon_17.png) no-repeat center;
                    background-size: 100% 100%;
                }
            }
            @media screen and (max-width: 800px) {
                padding-right: px2rem(60px);
                box-sizing: border-box;
                .department,
                .add,
                .number {
                    display: none;
                }
                .name {
                    width: 100%;
                    box-sizing: border-box;
                    text-align: left;
                    padding: 0 px2rem(20px);
                }
                //.icon-rotate {
                //    padding: 0;
                //    position: absolute;
                //    right: 0;
                //    top: 0;
                //    width: 30px;
                //    height: 100%;
                //    span {
                //        width: 20px;
                //        height: 20px;
                //    }
                //}
            }
        }
        .nr {
            display: none;
            position: relative;
            &:after {
                position: absolute;
                display: block;
                width: 100%;
                height: 95px;
                left: 0;
                bottom: -40px;
                z-index: 1;
                content: '';
                opacity: .2;
            }
            > div {
                font-size: 0;
                position: relative;
                z-index: 5;
                border-top: 1px solid #eef1f6;
                background: #fff;
                padding: 80px px2rem(52px) 84px px2rem(100px);
                font-size: 14px;
                color: #888;
                line-height: 30px;
                .mailbox {
                    position: absolute;
                    left: 100px;
                    bottom: -60px;
                    a {
                        font-size: 13px;
                        color: #333;
                        height: 40px;
                        line-height: 40px;
                        display: inline-block;
                        vertical-align: top;
                        padding: 0 35px;
                        border: 1px solid #020202;
                        position: relative;
                        transition: all 0.3s ease;
                        &:hover {
                            background: #5b5b5b;
                            border-color: #5b5b5b;
                            color: #fff;
                        }
                    }
                }
                h1 {
                    font-size: 24px;
                    color: #666;
                    line-height: 28px;
                    margin-bottom: 18px;
                }
                .p {
                    font-size: 14px;
                    color: #888;
                    line-height: 30px;
                }
            }
            .left {
                display: inline-block;
                vertical-align: top;
                width: (565/1200)*100%;
                box-sizing: border-box;
                //padding-left: 100px;
                padding-right: 52px;
            }
            .right {
                display: inline-block;
                vertical-align: top;
                width: (624/1200)*100%;
                box-sizing: border-box;
                padding-left: 100px;
                //padding-right: 52px;
                //border-left: 1px dashed #969696;
            }
            .mobile-title {
                display: none;
                font-size: 14px;
                color: #666;
                line-height: 24px;
                margin-bottom: 10px;
                p {
                    font-weight: bold;
                }
            }
            @media screen and (max-width: 800px) {
                &:after {
                    bottom: -15px;
                }
                > div {
                    padding: px2rem(40px) px2rem(20px);
                    &:after {
                        display: none;
                    }
                    h1 {
                        margin-bottom: px2rem(20px);
                        font-size: 18px;
                    }
                    .mailbox {
                        margin-top: px2rem(20px);
                        position: static;
                    }
                }
                .left {
                    padding: 0;
                    width: 100%;
                }
                .right {
                    margin-top: px2rem(40px);
                    padding: 0;
                    width: 100%;
                    border-left: none;
                }
                .mobile-title {
                    display: block;
                }
            }
        }
    }
}